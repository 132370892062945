@tailwind base;
html {
  scroll-behavior: smooth;
}
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,600;1,700&display=swap');
body {
  margin: 0;
  font-family: 'Josefin Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: aliceblue;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-3xl {
  font-family: 'Josefin Sans', sans-serif;
}

h2 {
  /* Italic josefin sans light */
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 600;
  font-style: italic;
}